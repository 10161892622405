* p {
  margin: 0;
  bottom: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  overflow-x: hidden;
}

::-webkit-scrollbar { /* chrome based */
  width: 0px;  /* ширина scrollbar'a */
  background: transparent;  /* опционально */
}

#root, html, body{
  margin: 0 0;
  padding: 0 0;
  width: 100%;
  height: 100%;
  background-color: #232123;
}



@font-face {
  font-family: 'Avenir';
  src: url("../public/fonts/AvenirNextCyr/AvenirNextCyr-Regular.eot");
  src: url("../public/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Avenir';
  src: url("../public/fonts/AvenirNextCyr/AvenirNextCyr-Medium.eot");
  src: url("../public/fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Avenir';
  src: url("../public/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf");
  src: url("../public/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Avenir';
  src: url("../public/fonts/AvenirNextCyr/AvenirNextCyr-Bold.ttf");
  src: url("../public/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }