.incoming {
  margin: 300px auto 0;
  max-width: 1328px;
}

.top-img {
  position: relative;
}

.top-img__img {
  display: block;
  width: 100%;
  min-height: 360px;
  object-fit: cover;
  border-radius: 40px;
}
@media (min-width: 768px) {
  .top-img__img {
    border-radius: 60px;
  }
}
.top-img__buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  text-align: end;
}
@media (min-width: 768px) {
  .top-img__buttons {
    padding: 68px 60px;
  }
}
.top-img__buttons .btn--blur {
  color: white;
  border-color: white;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 18px 51px 19px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-top: 5px;
}
@media (min-width: 768px) {
  .top-img__buttons .btn--blur {
    margin-top: 0;
    margin-left: 5px;
    width: auto;
  }
}
.top-img__buttons .place-btn,
.top-img__buttons .video-btn,
.top-img__buttons .gallery-btn {
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: #ffeb00;
  margin-left: 5px;
  margin-right: 0;
}
.top-img__buttons .place-btn {
  background-color: #242124;
}
.top-img__buttons .gallery-btn {
  background-color: #ffffff;
}

.label {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 120px;
  height: 40px;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  font-weight: bold;
  text-align: center;
  padding: 8px;
  background-color: #ffeb00;
  transform: rotate(-90deg);
  z-index: 1;
}

@media (min-width: 992px) {
  .numbers-number {
    font-size: 400px;
    line-height: 280px;
    color: #ffffff;
    font-weight: bold;
  }
  .numbers-item {
    margin-bottom: 90px;
  }
}

@media (width > 768px) and (width < 992px) {
  .numbers-item {
    margin-bottom: 70px;
  }
  .numbers-number {
    font-size: 30vw;
    line-height: 280px;
    font-weight: bold;
  }
}

@media (width < 768px) {
  .numbers-number {
    font-size: 30vw;
    line-height: 1;
    font-weight: bold;
  }
  .numbers-item {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .img-gallery img {
    border-radius: 60px;
  }
}

.img-gallery img {
  display: block;
  border-radius: 40px;
  width: 100%;
}

.numbers-number--yellow {
  color: #ffeb00;
}

.numbers-item__name {
  font-size: 32px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 31px;
}

.numbers-item__ico {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 25px;
  position: relative;
}

.numbers-item__ico:after {
  content: "";
  display: block;
  position: absolute;
  left: 100%;
  top: 50%;
  width: 200px;
  border-bottom: 1px solid #8890a6;
}

.numbers-item__text {
  font-size: 16px;
  color: #8890a6;
  font-weight: 500;
  max-width: 190px;
}

.numbers-item:last-child {
  margin-bottom: 0;
}

@media (width < 1521px) {
  hr.mt-5.mt-md-0 {
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2) !important;
    color: #ffeb00;
    border-top: 1px solid #ffeb00;
  }
}
@media (width > 1520px) {
  hr.mt-5.mt-md-0 {
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2) !important;
    color: #ffeb00;
    border-top: 1px solid #ffeb00;
  }
}

.imageContainer2 {
  width: 100vw !important;
  margin-left: calc((100% - 100vw) / 2) !important;
}

.map-pin {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.map-pin .ico {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  filter: drop-shadow(0px 20px 20px rgba(255, 138, 0, 0.4));
  background-color: #ffffff;
  margin-right: 32px;
}

.map-pin .text {
    font-size: 14px;
    color: #8890a6;
    font-weight: 500;
}